import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = ({ location, componentName, item, site }) => {
  // If component does not exist in acf folder, print error message
  if (!(componentName in AcfLayout)) {
    return (
      <div className="wrapper">
        {`Error: Component does not exist. `}
        {`Please create component "${componentName}.jsx" in src/acf folder `}
        {`and add export to src/acf/index.js`}
      </div>
    );
  }
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      site={site}
      {...item}
    />
  );
};

const Page = ({ data, location }) => {
  const { wordpressPost: page, site, wordpressWpSettings } = data;
  if (!page) return null;
  const { title, yoast, acf = {} } = page;
  const { layout } = acf;
  const { siteTitle } = wordpressWpSettings;
  return (
    <Layout location={location}>
      <SEO
        title={`${yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
          }`}
        desc={yoast.metaDescription}
        pathname={location.pathname}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_', '');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            site={site}
            location={location}
          />
        );
      })}
    </Layout>
  )
}

export default Previewable(Page, 'page');

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        functionsUrl,
        productsSlug
      }
    }
    wordpressWpSettings {
      siteTitle: title
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename,
          ... on WordPressAcf_HomepageBanner {
            id
            heading
            subtitle
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_Banner {
            id
            heading
            subtitle
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_ImageAndText {
            id
            heading
            textPosition
            backgroundColor
            content
            image {
              localFile {
                childImageSharp {
                  fixed(width: 476, height:500, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            buttonLink {
              url
              title
              target
            }
          }
          ... on WordPressAcf_ImageAndForm {
            id
            heading
            content
            form
            image {
              localFile {
                childImageSharp {
                  fixed(width: 476, height:500, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_CtaTiles {
            id
            tiles {
              heading
              content
              buttonLink {
                url
                title
                target
              }
            }
          }
          ... on WordPressAcf_CenteredText {
            id
            heading
            content
          }
          ... on WordPressAcf_StandoutText {
            id
            content
          }
          ... on WordPressAcf_IconListAndText {
            id
            heading
            content
            buttonLink {
              url
              title
              target
            }
            iconList {
              text
              icon
            }
          }
          ... on WordPressAcf_ProductCategoryTiles {
            id
            heading
            content
            categories {
              category
            }
          }
          ... on WordPressAcf_IconListAndImage {
            id
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            iconList {
              text
              icon
            }
          }
          ... on WordPressAcf_KeyProjects {
            id
            heading
          }
          ... on WordPressAcf_Wysiwyg {
            id
            content
          }
          ... on WordPressAcf_LinkTiles {
            id
            heading
            content
            tilesHeading
            tiles {
              tileTitle
              tileContent
              tileImage {
                localFile {
                  childImageSharp {
                    fixed(width: 600, height:250, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              tileLink
            }
          }
          ... on WordPressAcf_TeamMembers {
            id
            teamMembers {
              photo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              teamMemberName
              role
              bio
            }
          }
        }
      }
    }
  }
`
